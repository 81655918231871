<template>
	<v-card class="my-1 mx-auto" rounded="xl">
		<v-card-title class="py-0 d-flex flex-nowrap">
			<v-avatar class="mr-2 my-auto flex-shrink-1" size="48">
				<v-img alt="Avatar" :src="item.imgURL" :aspect-ratio="1 / 1" />
			</v-avatar>
			<div class="d-flex flex-nowrap flex-grow-1 justify-space-between">
				<router-link
					class="font-weight-bold text-decoration-none red--text flex-grow-1"
					style="word-break: normal"
					:to="{ name: 'User', params: { id: item.id } }"
					target="_blank"
				>
					{{ item.name.full }}
				</router-link>
			</div>
		</v-card-title>
		<v-card-text class="py-0">
			<v-row class="justify-content-between">
				<v-col cols="12" align-self="center" class="my-0">
					<v-chip
						small
						:loading="loadingDocument"
						:disabled="loadingDocument"
						:ripple="false"
						:color="item.documents.cv.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="item.documents.cv.uploaded ? callDownloadDocument(item.documents.cv.id, item.id) : callNoDocument()"
					>
						<v-icon left>mdi-cloud-download</v-icon>
						{{ $t('documents.cv') }}
					</v-chip>
					<v-chip
						small
						:loading="loadingDocument"
						:disabled="loadingDocument"
						:ripple="false"
						:color="item.documents.motivation.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="item.documents.motivation.uploaded ? callDownloadDocument(item.documents.motivation.id, item.id) : callNoDocument()"
					>
						<v-icon left>mdi-cloud-download</v-icon>
						{{ $t('documents.motivationLetter') }}
					</v-chip>
					<v-chip
						small
						:loading="loadingDocument"
						:disabled="loadingDocument"
						:ripple="false"
						:color="item.documents.records.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="item.documents.records.uploaded ? callDownloadDocument(item.documents.records.id, item.id) : callNoDocument()"
					>
						<v-icon left>mdi-cloud-download</v-icon>
						{{ $t('documents.records') }}
					</v-chip>
				</v-col>
				<v-col cols="12">
					<v-btn
						color="success darken-3"
						small
						rounded
						class="mx-1"
						:disabled="item.applied"
						:loading="loadingIncluding"
						@click="callIncludeUserInOffer(item.id, offer.id)"
					>
						{{ item.applied ? $t('settings.added') : $t('settings.add') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferTalentPoolUserItemMobile',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loadingIncluding: false
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	methods: {
		callDownloadDocument(documentID, userID) {
			this.loadingDocument = true
			this.downloadDocumentUser({ documentID, userID }).then(() => {
				this.loadingDocument = false
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		callIncludeUserInOffer(userID, offerID) {
			this.loadingIncluding = true
			this.includeUserInOffer({ userID, offerID })
				.then(({ success }) => {
					if (success) this.item.applied = true
				})
				.then(() => {
					this.loadingIncluding = false
				})
		},
		...mapActions('users', ['includeUserInOffer']),
		...mapActions('documents', ['downloadDocumentUser', 'noDocument'])
	}
}
</script>
